.footer {
    background-color: white;
    color: #333;
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .footer-left,
  .footer-right {
    flex: 1;
    margin: 0 10px;
  }
  
  .footer-left h4,
  .footer-right h4 {
    font-size: 18px;
    color: #023075;
    margin-bottom: 10px;
  }
  
  .footer-left p,
  .footer-right p {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
  }
  
  .footer-right {
    display: flex;
    flex-direction: column;
  }
  
  .contact-us,
  .address {
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-left,
    .footer-right {
      margin: 10px 0;
    }
  }
  