body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f7f9fc;
  color: #333;
}

.homepage-container {
  width: 100%;
}

/* Navbar styles */
.navbar {
  display: flex;
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo img {
  width: 60px;
  height: auto;
}


nav ul {
  display: flex;
}

nav ul li {
 list-style: none;
  
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #023075;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: x-large;
}

nav ul li a:not(.active):hover {
  background-color:#172554 ;
  color: #fff;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #023075;
  border-radius: 0.2rem;
  
}







.main-content {
  text-align: center;
  padding: 40px 20px;
}

.main-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #01337d;
}

.main-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}

.courses-section {
  text-align: center;
  padding: 40px 20px;
}

.courses-section h2 {
  font-size: 40px;
  margin-bottom: 30px;
  color: #023075;
}

.course-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.right-video .course-details {
  order: 1;
}

.right-video .video-container {
  order: 2;
}

.left-video .course-details {
  order: 2;
}

.left-video .video-container {
  order: 1;
}

.course-details {
  flex: 1;
  text-align: left;
}

.course-details h3 {
  font-size: 24px;
  color: #023075;
  font-weight: bolder;
}

.course-details p {
  font-size: 16px;
  color: #666;
}

.video-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: 25%; /* Adjusted aspect ratio for a shorter height */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.about-us {
  padding: 40px 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
  text-align: center;
}

.about-us h1 {
  font-size: 32px;
  color: #023075;
  font-weight: bolder;
}

.about-us p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .main-content, .courses-section, .about-us {
    padding: 20px 10px;
  }

  .course-card {
    flex-direction: column;
    padding: 20px 15px;
  }

  .right-video .course-details,
  .right-video .video-container,
  .left-video .course-details,
  .left-video .video-container {
    order: initial;
  }

  .video-container {
    padding-bottom: 56.25%; /* Standard aspect ratio for mobile */
    height: 0;
    width: 100%;
  }

  .video-container iframe {
    width: 100%;
    height: 100%;
  }




  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: row;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    width: 100%;
    text-align: center;
    }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}















  
  
  