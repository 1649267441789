/* MobileNavbar.css */

.mobile-navbar {
    background: #f7f9fc; /* Dark background color */
    color: #fff;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.navbar-content {
    display: flex;
}

.link {
    color: #fff;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 13px; /* Increase font size for better visibility */
    font-weight: bold;
}

.link:hover {
    background: #555; /* Darken background on hover */
    border-radius: 5px; /* Add rounded corners on hover */

}

.active {
    background: #555; /* Darken background for active link */
    border-radius: 5px; /* Add rounded corners for active link */
}


  