.user-study-material-list {
    margin-top: 20px;
}

.material-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    grid-gap: 20px;
}

.user-study-material-box {
    border: none;
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
    transition: transform 0.3s ease-in-out; /* Add transition for hover effect */
}

.user-study-material-box:hover {
    transform: translateY(-5px); /* Add hover effect */
}

.icon-container {
    font-size: 48px;
    margin-bottom: 20px;
    color: #5e72e4; /* Change icon color */
}

.material-title {
    font-size: 16px;
    color: #333; /* Change title color */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .material-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust column width for smaller screens */
    }
}

