@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Shared Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f7f9fc;
  color: #333;
}

.homepage-container {
  width: 100%;
}

/* Navbar styles */
.navbar {
  display: flex;
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo img {
  width: 60px;
  height: auto;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #023075;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  font-weight: bolder;
  font-size: x-large;
}

nav ul li a:not(.active):hover {
  background-color: #172554;
  color: #fff;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #023075;
  border-radius: 0.2rem;
}

@media screen and (max-width: 768px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: row;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}

/* Specific Sign-Up Form Styles */
.signup-container {
  max-width: 800px;
  margin: 100px auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333;
}

.form-header {
  text-align: center;
  margin-bottom: 20px;
}

.form-header h3 {
  margin: 0;
  font-size: 24px;
  color: #023075;
}

.input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.input-group label {
  display: flex;
  flex-direction: column;
}

.input-group label.full-width {
  grid-column: span 2;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f9fc;
  color: #333;
  font-size: 16px;
}

input::placeholder {
  color: #bbb;
}

.error {
  color: #ff6f6f;
  font-size: 12px;
  margin-top: 5px;
}

.sign-up-button {
  background-color: #4fc3f7;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.sign-up-button:hover {
  background-color: #1e88e5;
}

.link-container {
  text-align: center;
  margin-top: 15px;
}

.link-container a {
  text-decoration: none;
  color: #023075;
  font-weight: bold;
}

.link-container a:hover {
  text-decoration: underline;
}

.sign-up-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sign-up-modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.sign-up-modal-content h4 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}

.sign-up-modal-content p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.sign-up-modal-content button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.sign-up-modal-content button:hover {
  background-color: #45a049;
}




/* Sign in page*/

.signin-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333;
}


.signin-input-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}