/* Content.css */

/* Set background color for the entire page */
body {
  background-color: #f0f0f0; /* Light gray background color */
}

/* Videos Grid */
.videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* .video-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9; 
  transition: transform 0.3s ease-in-out; 
} */

.video-card {
  position: relative;
  border: 1px solid #ccc;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.video-card:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.new-video-label {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #ff6f61;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: bold;
  z-index: 1;
}

.play-icon {
  cursor: pointer;
  text-align: left;
  color: #023075; /* Dark color for play icon */
}

/* Loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); /* Adjust for navbar height */
}

.loader-text {
  font-size: 20px;
  color: #333;
}

/* User Live Class List Box */
.user-live-class-list-box {
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  background-color: #f9f9f9; /* Light background color */
  color: #333; /* Dark color for text */
  transition: transform 0.3s ease-in-out; /* Smooth transition on hover */
  flex: 1;
  margin-right: 20px;
}

.user-live-class-list-box:hover{
  transform: scale(1.02);
}

.user-study-material-list-box {
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  background-color: white; 
  color: #333; /* Dark color for text */
  transition: transform 0.3s ease-in-out; /* Smooth transition on hover */
  flex: 1;
  
}

.user-study-material-list-box:hover{
  transform: scale(1.02);
}

.user-content-options {
  display: flex;
  justify-content: space-between; /* Adjust spacing between boxes */
}


@media screen and (max-width: 768px) {
  .videos-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }

  .video-card {
    padding: 10px;
  }

  .play-icon {
    font-size: 16px;
  }

  .loader {
    height: calc(100vh - 60px); /* Adjust for navbar height */
  }

  .user-live-class-list-box {
    padding: 10px;
  }

  .user-study-material-list-box{
    padding: 10px; 
  }

  .student-container {
    width: 100%;
  }
  
  .student-main-content {
    margin-top: 80px;
    width: 100%; /* Ensure full width */
    max-width: 1200px; /* Adjust as needed */
  }
}




.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  background-color: white;
  color: black;
}

.search-input::placeholder {
  color: #999;
}

.search-input:focus {
  /* border-color: #1d1d1e; */
  box-shadow: 0 0 0 0.2rem rgba(35, 35, 35, 0.25);
}



.user-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.user-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}





/* Tab List */
.react-tabs__tab-list {
  border-bottom: 2px solid #023075;
  padding: 0;
  margin: 0;
  /* display: flex;
  justify-content: space-around; */
  background-color: #f7f9fc;
}

/* Individual Tab */
.react-tabs__tab {
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #023075;
  border: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease, color 0.3s ease;
}

.react-tabs__tab--selected {
  border-bottom: 2px solid #f7f9fc;
  color: #f7f9fc;
  background-color: #023075;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab--disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* .react-tabs__tab:hover:not(.react-tabs__tab--selected) {
  color: #f7f9fc;
} */











/* Add this CSS to your UserCourseAccess.css file */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  background-color: #fff;
  margin: 2rem;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 900px;
  width: 100%;
  animation: fadeIn 0.3s ease-in-out;
}



.modal-title {
  font-size: 1.5rem;
  color: #023075;
  margin: 0;
  font-weight: bolder;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

.close {
  color: #aaa;
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  z-index: 10000; /* Ensures close button is on top */
}

.close:hover,
.close:focus {
  color: #000;
}

.video-player {
  position: relative;
  z-index: 2;
  margin-top: 2rem; /* Ensure the close button is not overlapped */
}

iframe {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}





  
  