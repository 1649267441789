.live-class-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .live-class-box {
    background-color: #f4f4f4; /* White background */
    color: #000; /* Black text */
    /* border: 1px solid #000;  */
    border-radius: 10px;
    padding: 20px;
    width: 290px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 250px;
    overflow: hidden;
  }
  
  
  .live-class-box:hover {
    transform: scale(1.1);
  }

  .attendance-download-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .date-input-container {
    margin-right: 10px;
    flex: 1; /* Allow containers to grow and occupy available space */
  }
  
  .date-input-container label {
    color: #111010;
  }
  
  .date-input-container input[type="date"] {
    background-color: #f1f2f3;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%; /* Take up full width */
  }
  
  .date-input-container input[type="date"]::placeholder {
    color: #ccc;
  }

  .button-container {
    padding-top: 25px; /* Adjust as needed */
    padding-bottom: 10px; /* Adjust as needed */
  }
  
  .download-button {
    background-color: #101010;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    line-height: 1;
    height: 40px; /* Adjust height as needed */
  }
  
  .download-button:hover {
    background-color: #3c3d3e;
  }
  
  /* Media query for responsiveness */
  @media (min-width: 768px) {
    .date-input-container {
      margin-right: 10px;
      width: auto; /* Reset width to auto for larger screens */
    }
  
    .download-button {
      width: auto;
      padding: 10px 20px;
    }
  }
  
  
  
  
  
  
  