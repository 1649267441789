.attendance-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .attendance-table th,
  .attendance-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .attendance-table th {
    background-color: #f2f2f2;
  }
  
  .attendance-table td.P {
    color: green;
  }
  
  .attendance-table td.A {
    color: red;
  }
  
  