/* CourseDetails.css */

.course-details-container {
  background-color: #696969;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Set a percentage width for responsiveness */
  max-width: 1000px; /* Optional: You can set a maximum width if needed */
  margin: auto; /* Center the container horizontally */
  overflow: auto; /* Add scrollbars if content overflows */
}

.course-details-content {
  text-align: center; /* Center align content */
}

.course-details-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
}

.course-details-content p {
  font-size: 16px;
  margin-bottom: 20px;
  color: white;
}

.course-details-content button {
  padding: 10px 20px;
  background-color: #090909;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.course-details-content button:hover {
  background-color: #3e3f40;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .course-details-container {
      padding: 15px;
      width: calc(100% - 30px); /* Adjust width to fit within screen */
  }

  .course-details-content h2 {
      font-size: 20px;
  }

  .course-details-content p {
      font-size: 14px;
  }

  .course-details-content button {
      padding: 8px 16px;
      font-size: 14px;
  }
}

