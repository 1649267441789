/* General Styles */
.dashboard-container {
    padding: 20px;
  }
  
  .dashboard-title {
    text-align: center;
    color: #023075;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  
  /* Grid Layout */
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Card Styles */
  .dashboard-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s;
    text-align: center;
  }
  
  .dashboard-card:hover {
    transform: translateY(-10px);
  }
  
  .dashboard-card-link {
    text-decoration: none;
    color: inherit;
  }
  
  .dashboard-card-title {
    color: #023075;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .dashboard-card-description {
    color: #555555;
    font-size: 1rem;
  }
  
  /* Loading Spinner */
  .user-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .user-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #023075;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  