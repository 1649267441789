/* StudentLayout.css */
.student-layout {
    display: flex;
  }
  
  .content-wrapper {
    flex-grow: 1;
  }
  
  .sidebar-open .content-wrapper {
    margin-left: 250px;
  }
  
  @media screen and (max-width: 767px) {
    .content-wrapper {
      margin-left: 0;
    }
  }



  
  
  
  
  
  
  