.student-main-content {
  /* padding: 20px; */
  background-color: #f7f9fc;
  /* border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.title {
  color: #023075;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2.5em;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 3px solid #023075;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 30px;
  background: linear-gradient(90deg, rgba(2,48,117,0.1) 0%, rgba(2,48,117,0.2) 50%, rgba(2,48,117,0.1) 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.search-input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #023075;
  border-radius: 8px;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width */
  margin-bottom: 20px; /* Adds space below the search bar */
}


.course-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.course-link {
  text-decoration: none;
  color: inherit;
  width: 100%; /* Ensure link takes the full width for better layout */
}

.course-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%; /* Ensure the box takes full width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap */
}

.course-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.course-content {
  flex: 1;
}

.course-heading {
  color: #023075;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.course-description {
  color: #666;
  font-size: 16px;
}

.subscribe-button {
  background-color: #023075;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px; /* Ensure there's spacing in case it wraps to the next line */
}

.subscribe-button:hover {
  background-color: #014a9c;
}

@media (max-width: 600px) {
  .course-box {
      flex-direction: column;
      align-items: flex-start; /* Align items to the start for better layout */
  }

  .subscribe-button {
      margin-left: 0;
      margin-top: 20px;
      width: 100%; /* Make the button take full width on mobile */
      text-align: center; /* Center the button text */
  }
}


