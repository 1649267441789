/* AddCourseForm.css */

/* AddCourseForm container */
.add-course-container {
  max-width: 100%;
  margin: 0 auto;
}

.add-course-container form {
  display: flex;
  flex-direction: column;
}

.add-course-container label {
  margin-bottom: 10px;
}

.add-course-container input,
.add-course-container textarea {
  padding: 8px;
  margin-bottom: 10px;
}

.add-course-container button {
  background-color: #222222;
  color: #fff;
  padding: 10px;
  cursor: pointer;
} 

.add-course-container button:hover {
  background-color: #abadae;
}

/* Container for the Course Details */
.course-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Subscription Box container */
.subscription-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items to next line when there's no space */
  justify-content: space-between;
}

/* Subscription Box styles */
.subscription-box,
.subscribed-users-box,
.live-class-list-box,
.study-material-box {
  text-decoration: none;
  color: #333;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.videos-box-container {
  
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.subscription-box:hover,
.subscribed-users-box:hover,
.live-class-list-box:hover,
.study-material-box:hover {
  transform: scale(1.02);
}

/* Videos Box styles */
.videos-box-container {
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.video-table {
  width: 100%;
  border-collapse: collapse;
}

.video-table th,
.video-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  word-break: break-word; /* Break long words */
}

/* Responsive styles */
@media (max-width: 600px) {
  .course-info-box,
  .subscription-box {
    padding: 10px;
  }

  .video-table th,
  .video-table td {
    padding: 8px;
  }
}

/* Modal styles */
.success-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.success-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
}

.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}







.video-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.search-bar {
  width: 30%;
}

.sort-button {
  margin-left: 10px;
}

.add-video-button {
  margin-left: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.pagination .btn {
  margin: 0 2px;
}
