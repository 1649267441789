.error-message {
  color: red;
}

.live-class-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.live-class-item {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px;
  background-color: #f9f9f9;
}

.join-button {
  background-color: #080808;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.join-button:hover {
  background-color: #7a7a7b;
}

.disabled-button {
  background-color: #ccc;
  color: #666;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
}

  