/* AdminDashboard.css */

/* Base styles */
.admin-dashboard {
  display: flex;
}

.sidebar {
  height: 100vh;
  width: 250px;
  background-color: #f7f9fc;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.sidebar-header {
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #023075;
  font-weight: bold;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px; /* Ensure content does not overlap with the sidebar */
  overflow-x: auto; /* Prevent content from being hidden */
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .sidebar {
      width: 100%;
      height: auto;
      position: relative;
  }

  .main-content {
      margin-left: 0;
      padding: 10px;
  }

  .admin-dashboard {
      flex-direction: column; /* Stack sidebar on top of main content on smaller screens */
  }

  .sidebar ul li {
      font-size: 14px;
  }
}

  
  
  
  
  