/* AdminCourseList.css */

/* Main Container */
.admin-course-list-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Title */
  .admin-course-list-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
    text-align: left;
  }
  
  /* Box Container */
  .admin-course-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .admin-course-box {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  /* Search Bar */
  .admin-course-search-bar {
    border: 2px solid #023075;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  /* Table */
  .admin-course-table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  
  .admin-course-table th,
  .admin-course-table td {
    vertical-align: middle;
    padding: 12px;
    text-align: center;
  }
  
  .admin-course-table th {
    background-color: #023075;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .admin-course-table tbody tr {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .admin-course-table tbody tr:hover {
    transform: scale(1.02);
  }
  
  .admin-course-table td {
    border: none;
  }
  
  /* Actions Button */
  .admin-course-btn-group .dropdown-toggle::after {
    margin-left: 10px;
  }
  
  .admin-course-btn-group .dropdown-menu {
    min-width: 120px;
    padding: 0;
  }
  
  .admin-course-dropdown-item {
    padding: 10px 20px;
    font-size: 0.95rem;
    color: #333;
  }
  
  .admin-course-dropdown-item:hover {
    background-color: #023075;
    color: #fff;
  }
  
  /* Pagination */
  .admin-course-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .admin-course-pagination .btn {
    margin: 0 5px;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .admin-course-pagination .btn-light:hover {
    background-color: #023075;
    color: #fff;
  }
  
  /* Add Course Button */
  .admin-course-add-btn {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #023075;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    
  }
  
  .admin-course-add-btn:hover {
    background-color: #023075;
    color: #fff;
    text-decoration: none;
  }
  
  /* Small Screen Adjustments */
  @media (max-width: 768px) {
    .admin-course-list-title {
      font-size: 1.5rem;
    }
  
    .admin-course-table th,
    .admin-course-table td {
      font-size: 0.9rem;
    }
  }
  