/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
}

.sidebar {
  background: #ffffff; /* White background */
  color: #1f1f1f; /* Dark text for contrast */
  height: 100vh;
  transition: width 0.5s;
  border-right: 1px solid #ddd; /* Light border for separation */
}

.sidebar.open {
  width: 250px;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  border-bottom: 1px solid #ddd; /* Light border for separation */
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: auto;
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  color: #1f1f1f; /* Dark text color */
  padding: 10px 20px;
  gap: 15px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
}

.link:hover {
  background: #f0f0f0; /* Light hover background */
  color: #023075; /* Dark color on hover */
}

.active {
  background: #e0e0e0; /* Slightly darker background for active link */
  color: #023075; /* Dark color for active link */
}

.icon,
.link_text {
  font-size: 20px;
}

.link_text {
  display: none;
  transition: display 0.5s;
}

.sidebar.open .link_text {
  display: block;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.5s;
}

.main-content {
  margin-left: 90px;
}

.toggle-bar {
  position: absolute;
  top: 20px;
  right: -30px;
  cursor: pointer;
}












/* Modal */
.modal {
  display: none; /* Hide modal by default */
  position: fixed; /* Fixed position to overlay entire screen */
  z-index: 1000; /* Ensure modal is on top of other content */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scrolling if content overflows */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe; /* White background */
  margin: 10% auto; /* Center modal vertically and horizontally */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Reduce the width of the modal */
  max-width: 400px; /* Limit maximum width of modal */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  text-align: left; /* Align text to the left */
}

/* Profile Header */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Profile Title */
.profile-title {
  font-size: 24px;
  color: #023075;
  margin: 0;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

/* Profile Details */
.profile-details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #023075;
}

.profile-info {
  font-size: 18px;
  color: #023075;
  font-family: 'Arial', sans-serif;
}

.profile-info p {
  margin: 5px 0;
}



/* Close Button */
.close {
  color: #aaa; /* Light gray text color */
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000; /* Black text color on hover */
  text-decoration: none;
  cursor: pointer;
}

.btn.btn-dark {
  background-color: #023075;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.btn.btn-dark:hover {
  background-color: #00264d;
}














